// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

html,body {
    height:100%
}
body {
    background-image:url(/images/achtergr_MNapp_1920x1080.jpg);
    background-size:cover;
    font-family: Calibri, Arial, sans-serif;
}

.vertical-center {
    min-height:100%;
    min-height:100vh;
    display:-webkit-box;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
    display:flex;
    -webkit-box-align:center;
    -webkit-align-items:center;
    -moz-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.container {
    background-color:#fff;
}

.title {
    color: white;
    margin-top: 25px;
    background: none !important;
    margin-bottom: -75px;
}
.titleeee:after {
    content: "";
    display: block;
    clear: both;
    box-sizing: border-box;
}

.accordion {
    padding-top: 13px !important;
}

.text-primary {
    padding:10px 10px 0 5px
}

.header {
    background-color:#fafafa
}

.form-label.required:after {
    content:" *";
    color:red
}

#fullName {
    font-weight: bolder;
}

.accordion-button {
    background-color: var(--bs-accordion-active-bg) !important;
}

#addressPreview {
    font-style: italic;
}

#General, #Safety, #Privacy {
    margin-left:-1.5em
}
canvas {
    border:1px solid
}

.row.link {
    margin-top: -25px;
}

.validationError {
    border-radius: 5px !important;
    border:#FF0000 1px solid !important;
}

.btn-success, .btn-secondary {
    color: black !important;
    --bs-btn-disabled-color: black !important;
    background-color: #e88314 !important;
    border-bottom-color: #e88314 !important;
    border-left-color: #e88314 !important;
    border-right-color: #e88314 !important;
    border-top-color: #e88314 !important;
}

.form-check-input:checked {
    background-color: #e88314 !important;
    border-color: #e88314 !important;
}
